import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../../../../../assets/icons/chevron-down.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../../assets/icons/check-mark.svg";
import { getDates } from "../TrendsLineChart";

export const types = [
  { label: "Guest Reviews", value: "guest_reviews" },
  { label: "Competitor Prices", value: "competitor_prices" },
  // { label: "Segmentation", value: "segmentation" },
  // { label: "Room Rates", value: "room_rates" },
  // { label: "Website Traffic", value: "website_traffic" },
  // { label: "ADR Index", value: "adr_index" },
  // { label: "Occ. Index", value: "occ_index" },
  // { label: "RavPAR Index", value: "ravpar_index" },
  { label: "Booking Window", value: "booking_window" },
  { label: "Rooms OOO", value: "rooms_ooo" },
  // { label: "Visits by Device", value: "visits_by_device" },
  { label: "Revenue by Channel", value: "revenue_by_channel" },
  // { label: "Referral Sources", value: "referral_sources" },
];

export function FilterSelect({ filter, setFilter }) {
  const [showFilter, setShowFilter] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedType = types.find((type) => type.value === filter.type);
  return (
    <div ref={containerRef} className="relative">
      <button
        type="button"
        onClick={() => setShowFilter((p) => !p)}
        className="bg-white hover:bg-white border border-[#E5E7EB] text-[#111827] rounded-[8px] px-3 py-[7px] h-auto font-normal text-sm flex items-center gap-1 hover:border-[#E5E7EB]"
      >
        <div className="text-center flex-1 whitespace-nowrap overflow-hidden text-ellipsis text-[#000000] text-[12px] font-medium leading-[11px]">
          {selectedType?.label}
        </div>
        <ChevronDownIcon
          className={`w-2.5 h-2.5 ${
            showFilter
              ? "rotate-180 pb-0.5 [&_path]:stroke-black"
              : "pt-0.5 [&_path]:stroke-neutral-600"
          }`}
        />
      </button>
      {showFilter && (
        <div className="absolute z-[1] left-0 right-[30%] mt-1 w-[200px] p-2 bg-white rounded shadow-[0_2px_8px_rgba(0,0,0,0.12)] rounded-lg border-[#E5E7EB] border border-[#E5E7EB] flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch h-[125px] flex-col justify-start items-start flex">
            {types.map((type) => (
              <button
                onClick={() => {
                  const currentYear = new Date().getFullYear();

                  filter.type !== type.value &&
                    setFilter({
                      ...filter,
                      type: type.value,
                      ...getDates(
                        type.value === "competitor_prices"
                          ? "next12"
                          : currentYear
                      ),
                    });
                }}
                type="button"
                key={type.value}
                className="self-stretch hover:bg-[#E1DDD2] h-[25px] p-2 rounded-sm justify-between items-center inline-flex"
              >
                <div className="text-[#161938] text-xs font-medium leading-3">
                  {type.label}
                </div>
                {filter.type === type.value && (
                  <CheckMarkIcon className="[&_path]:stroke-primary w-3 h-3" />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
